import './App.css';
import Body from './components/Body';
import SliderComponent from './components/SliderComponent';





function App() {
  return (
    <div>
      <Body />
      <SliderComponent />
    </div>
  );
}





export default App;
